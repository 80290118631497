<template>
  <AppPage :name="'hotsale2h'">
    <div class="main">
      <div class="content">
        <div class="hotsale2h-content p-rel">
          <!-- 分类导航 -->
          <div class="category-wrap" >
            <div class="category-list flex" style="height: 20px; padding-left: 8px;">
              <div v-for="(e,i) in categorys" :key="i" class="category-item" :class="e.cname == activeCategory.cname ? 'active' : ''" :data-cid="e.cid">{{e.cname}}</div>
            </div>
          </div>


          <!-- 商品列表容器 -->
          <div class="goods-list swiper-container" style="padding-top: 15px; padding-bottom: 20px;" ref="swiperContainer">
            
            <div class="swiper-wrapper">
              <div v-for="(e,i) in categorys" :key="i" class="swiper-slide" :data-cid="e.cid" :data-cname="e.cname"></div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <div class="ml-20"></div>
    <div class="side">
      <AppQRCode />
      <VideoIntro />
      <AppComment />
      <ContactUs />
      <Copyright />
    </div>
  </AppPage>
</template>
<script>
import { fetchCategory, fetchGoods } from "@/api/modules/hotsale";
import { AppId, userChannelId } from "@/constants";
export default {
  components: {
  },
  data() {
    return {
      touchPageEnd: false,
      pageScrolling: false,
      categorys:[],
      loading: false,
      activeCategory:{},
      scrolling: false,
      categoryPageData: []
    };
  },
  created() {},
  computed: {},
  mounted() {

    fetchCategory().then(res => {

      // 设置分类元数据到页面缓存
      this.categorys = res.data

      // 设置分类各自的分页数据
      this.categorysPageData = this.categorys.map(e => {
        return {
          pageNo: 1,
          pageSize: 10,
          cid: e.cid,
          cname: e.cname,
          goodsList: [],
          isLastPage: 0
        }
      })

      // 设定处于活动状态的分类，此处用第一个
      this.activeCategory = this.categorysPageData[0]


      // 加载活动分类商品列表
      // this.loadActiveCategoryData()


      // 初始化swiper组件并把活动分类的容器设定为显示状态
      // pageData.listSwiper = new Swiper('.goods-list', {
      //   on: {
      //     init: function () {
      //       console.log("swiper init: ", this.activeIndex)
      //     },
      //     'slideChangeTransitionEnd': function () {

      //       var $currentSlide = $(".swiper-slide:eq(" + this.activeIndex + ")")

      //       // 切换活动分类
      //       this.activeCategory = this.categorysPageData.find(function (e) {
      //         return e.cid == $currentSlide.data("cid")
      //       })

      //       // 如果目标分类的商品列表为空则加载第一页数据，有内容的等用户触发页面触底事件时加载下一页
      //       if (this.activeCategory.goodsList.length == 0) {
      //         loadActiveCategoryData()
      //       }

      //       // 移动和高亮导航栏
      //       var $activeItem = $(".category-item[data-cid='" + this.activeCategory.cid + "']")
      //       $activeItem.addClass("active").siblings().removeClass("active")
      //       moveNavBar($(".category-wrap"), $activeItem)

      //     }
      //   }
      // })

    })

    // // 监听页面滚动
    // var self = this;

    // // 采用事件节流方式监听页面滚动
    // window.onscroll = function () {
    //   self.pageScrolling = true;
    // };

    // setInterval(function () {
    //   if (self.pageScrolling) {
    //     self.pageScrolling = false;

    //     var pageScrollTop = document.documentElement.scrollTop;
    //     var pageHeight = document.getElementById("app").offsetHeight;
    //     var borderPadding = 80;

    //     if (
    //       pageScrollTop + document.documentElement.offsetHeight >
    //       pageHeight - borderPadding
    //     ) {
    //       self.touchPageEnd = true;
    //     } else {
    //       self.touchPageEnd = false;
    //     }

    //     console.log("touchend:", self.touchPageEnd);
    //   }
    // }, 500);
  },
  methods: {
    // loadActiveCategoryData() {
    //   this.loading = true

    //   try {
    //     getGoodsList({
    //       appId: APP_ID,
    //       userId: userId,
    //       pageNo: this.activeCategory.pageNo,
    //       userChannelId: 6,
    //       cid: this.activeCategory.cid
    //     }).then(function (res) {

    //       // 是否末页与当前页
    //       this.activeCategory.isLastPage = res.data.isLastPage
    //       this.activeCategory.pageNo = res.data.pageNo

    //       // 在原数据中加入新的
    //       if (res.data.list) {
    //         this.activeCategory.goodsList = this.activeCategory.goodsList.concat(res.data.list)


    //         // 渲染新数据
    //         var $targetSwiperSlide = $(".swiper-slide[data-cid='" + this.activeCategory.cid + "']")
    //         var list = res.data.list.map(function (e, idx) {
    //           // 排名默认从1开始，如果已加载了一页数据，则下一页名次叠加上一页的长度
    //           var rank = idx + 1, rankHtml = ''
    //           if (this.activeCategory.pageNo == 1 && rank < 11) {
    //             if (rank < 4) {
    //               rankHtml = '<img src="images/rank-' + rank + '@2x.png" style="width: 20px; height: 26px;" />'
    //             } else {
    //               rankHtml = '<div class="p-rel"><img src="images/corner@2x.png" class="block" style="width: 20px; height: 20px;"><span class="block fs-13 text-999 text-left p-abs" style="width: 100%; height: 100%; z-index: 1;left: 0; top: 0;padding-left: 4px;">' + rank + '</span></div>'
    //             }
    //           }

    //           return '<div class="goods-item-wrap p-l-10 p-r-10 bg-f6">\
    //             <div class="goods-item p-t-10 p-l-10 p-r-10 p-b-15 bg-white br-5 flex p-rel open-app-goods" data-platform="'+ e.platform + '" data-numiid="' + e.numIid + '" data-item-url="' + e.itemUrl + '">\
    //               <div><img src="'+ e.pic + '" style="width: 28vw; height: 28vw;" class="br-5 block"></div>\
    //               <div class="m-l-15 flex flex-direction-column flex-main-between">\
    //                 <div class="goods-title text-limit-2 text-333 fs-14">'+ e.title + '</div>\
    //                 <div>\
    //                   <div class="salse-count-wrap">\
    //                     <div class="sales-count fs-11 text-white inline-block"><img src="https://shouqu.oss-cn-shanghai.aliyuncs.com/cms/160/images/1654486147822930182.png" style="width: 18px; float: left; transform: translateY(-2px);"><span>近2小时售出'+ e.twoHoursSales + '件</span></div>\
    //                   </div>\
    //                   <div class="price-wrap flex flex-cross-end m-t-10">\
    //                     <div class="now-price text-primary fs-20 fw-700">¥'+ parseFloat((e.zkFinalPrice - e.couponAmount).toFixed(2)) + '</div>\
    //                     <div class="zk-price m-l-5 fs-11 text-9b">¥'+ parseFloat((e.zkFinalPrice).toFixed(2)) + '</div>\
    //                   </div>\
    //                 </div>\
    //               </div>\
    //               <div class="shop-name p-abs fs-11 text-9b">'+ e.shopType + '</div>\
    //               <div class="rank p-abs">'+ rankHtml + '</div>\
    //             </div>\
    //           </div>'
    //         })
    //         $targetSwiperSlide.append(list)


    //       }

    //       this.loading = false
    //       $(".loading").addClass("hidden")
    //     })
    //   } catch (ex) {
    //     $(".loading").addClass("hidden")
    //   }
    // },
    loadGoods() {
      fetchCategory().then((res) => {
        if (res.code == 200) {
          if (res.data) {
            this.defaultCategory = res.data[0];
            fetchGoods({
              appId: AppId,
              userId: 5,
              pageNo: 1,
              userChannelId,
              cid: this.defaultCategory.cid,
            }).then((resGood) => {
              if (resGood.code == 200 && resGood.data && resGood.data.list) {
                this.list = resGood.data.list.slice(0, 3);
              }
            });
          }
        }
      });
    },
  },
};
</script>
<style lang="css" scoped>
.main {
  width: 846px;
}
.content {
  background: white;
}
.side {
  width: 314px;
}


.category-wrap {
  overflow-x: auto;
}
.category-item {
  color: #9b9b9b;
  font-size: 14px;
  line-height: 18px;
  height: 18px;
  position: relative;
  padding: 0 10px;
  flex-shrink: 0;
}
.category-item.active {
  color: #333;
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
}
</style>
